import React from 'react';
import ReactPlayer from 'react-player';
import styles from './BackgroundVideo.style';

interface BackgroundVideoProps {
  url?: string;
}

const BackgroundVideo: React.FC<BackgroundVideoProps> = ({ url }) => {
  if (!url) return null;

  return (
    <div style={styles.container}>
      <ReactPlayer
        url={url}
        playing
        width='100%'
        height='100%'
        muted
        loop
        config={{
          youtube: {
            playerVars: { showinfo: 0 },
          },
        }}
        style={styles.video}
      />
    </div>
  );
};

export default BackgroundVideo;
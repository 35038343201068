import React from 'react';
import { Typography } from '@material-ui/core';
import { css } from 'aphrodite';
import { connect } from 'react-redux';

import { history } from "../../helpers/history";

import { ProfileMenu } from '../../components';

import { PRISM_PART, ROLES } from '../../utils/Enum';

import { UserActions } from '../../actions';

import styles, { extendedStyles } from './HomeNavBar.style';


const DISPLAY_BY_NAME = {
  [PRISM_PART.YELLOW]: 'Assets',
  [PRISM_PART.PINK]: 'Financial overview',
  [PRISM_PART.GREEN]: 'OD',
  [PRISM_PART.PURPLE]: 'ED',
  [PRISM_PART.LIGHT_BLUE]: 'EFers',
  [PRISM_PART.BLUE]: 'Focus Area',
};

class HomeNavbar extends React.Component {
  constructor() {
    super();

    this.state = {
      selectedItemMap: {
        [PRISM_PART.YELLOW]: false,
        [PRISM_PART.PINK]: false,
        [PRISM_PART.PURPLE]: false,
        [PRISM_PART.GREEN]: false,
        [PRISM_PART.BLUE]: false,
        [PRISM_PART.LIGHT_BLUE]: false,
      },
    };
  }

  render() {
    const initials = this.getUserInitials();
    const options = this.getOptions();

    return (
      <div style={styles.wrapper}>
        <nav style={styles.nav}>
          <div
            style={styles.subNav}
          >
            <div style={styles.itemsWrapper}>
              {this.renderNavItems()}
            </div>
            <div style={{ ...styles.itemsWrapper, ...styles.wrapperRight }}>
              {this.renderNavItems(3)}
            </div>
          </div>
          <div
            style={styles.profileMenuContainer}
            className={css(extendedStyles.profileMenuContainer)}
          >
            <ProfileMenu initials={initials} options={options} type="outline" />
          </div>
        </nav>
      </div>
    );
  }

  renderNavItems = (offset = 0) => {
    const { selectedNavItem } = this.props;
    const { selectedItemMap } = this.state;

    const prismParts = Object.values(PRISM_PART).slice(offset, offset + 3);

    let selectedItem = null

    for (const [key, val] of Object.entries(selectedItemMap)) {
      if (val)
        selectedItem = key;
    }

    return prismParts.map((prismPart) => {
      let isSelected = prismPart === selectedNavItem;

      if (!isSelected && selectedItem === prismPart)
        isSelected = true;

      return (
        <div
          key={prismPart}
          onMouseEnter={() => this.handleItemSelection(prismPart, true)}
          onMouseLeave={() => this.handleItemSelection(prismPart, false)}
          onClick={this.handleClick.bind(null, prismPart)}
          style={styles.itemContainer}
          className={css(extendedStyles.itemContainer)}
        >
          <div style={styles.itemWrapper}>
            <Typography
              style={styles.itemName}
              className={css(isSelected && extendedStyles[prismPart])}
            >
              { DISPLAY_BY_NAME[prismPart] }
            </Typography>
          </div>
        </div>
      );
    });
  }

  handleItemSelection = (name, isSelected) => {
    const { onItemHover } = this.props;
    const { selectedItemMap } = this.state;

    if (onItemHover)
      onItemHover(name, isSelected);

    selectedItemMap[name] = isSelected;

    this.setState({ selectedItemMap });
  }

  handleClick = (prismPart) => {
    const { onItemClick } = this.props;

    if (onItemClick)
      onItemClick(prismPart);
  }

  handleLogout = () => {
    const { logout } = this.props;

    logout();
    history.push('/login');
  }

  handleAdminManage = () => {
    history.push('/admin/settings');
  }

  getUserInitials = () => {
    const { user } = this.props;

    try {
      const { firstName, lastName } = user;

      return `${firstName} ${lastName}`.split(' ').map((n) => n.toUpperCase()[0]).join('');
    } catch {
      return '';
    }
  }

  getOptions = () => {
    const { user } = this.props;

    const options = [
      { id: 'options-menu-logout', callback: this.handleLogout, name: 'Logout', icon: 'power-off'}
    ];

    if (user.roleId === ROLES.ADMIN)
      options.unshift({ id: 'options-menu-admin-manage', callback: this.handleAdminManage, name: 'Admin settings', icon: 'user-friends'})

    return options;
  }
}

function mapState(state) {
  const { loggingIn, user, navMap } = state.authentication;

  return { loggingIn, user, navMap };
}

const actionCreators = {
  logout: UserActions.logout
};

const connectedHomeNavbar = connect(mapState, actionCreators)(HomeNavbar);
export default connectedHomeNavbar;

import { userConstants } from '../constants';
import { userService } from '../services';
import { AlertActions } from './';

export default {
    activate,
    checkToken,
    loginWithGoogle,
    resendInvitation,
    logout,
    fetch,
    create,
    update,
    delete: _delete
};

function checkToken(token) {
    return dispatch => {
        dispatch(request());

        return userService.checkActivationToken(token)
            .then(
                res => {
                    dispatch(success(res.payload));
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: userConstants.VERIFY_ACTIVATE_REQUEST } }
    function success(isAuthorizedToken) { return { type: userConstants.VERIFY_ACTIVATE_SUCCESS, isAuthorizedToken } }
    function failure(error) { return { type: userConstants.VERIFY_ACTIVATE_FAILURE, error } }
}

function activate(token) {
    return dispatch => {
        dispatch(request());

        return userService.activate(token)
            .then(
                res => {
                    dispatch(success(res.payload));

                    return res.payload;
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: userConstants.ACTIVATE_REQUEST } }
    function success(user) { return { type: userConstants.ACTIVATE_SUCCESS, user } }
    function failure(error) { return { type: userConstants.ACTIVATE_FAILURE, error } }
}

function loginWithGoogle(code) {
    return dispatch => {
        dispatch(request({ code }));

        return userService.loginWithGoogle(code)
            .then(
                res => {
                    const user = res;

                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(AlertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_GOOGLE_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_GOOGLE_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_GOOGLE_FAILURE, error } }
}

function logout() {
    userService.logout();

    return { type: userConstants.LOGOUT };
}

function fetch() {
    return dispatch => {
        dispatch(request());

        userService.getAll()
            .then(
                users => dispatch(success(users)),
                error => {
                    const alertError = error instanceof Object
                        ? error.message
                        : error;

                    dispatch(failure(error));
                    dispatch(AlertActions.error(alertError));
                }
            );
    };

    function request() { return { type: userConstants.FETCH_REQUEST } }
    function success(users) { return { type: userConstants.FETCH_SUCCESS, users } }
    function failure(error) { return { type: userConstants.FETCH_FAILURE, error } }
}

function create(user) {
    return dispatch => {
        dispatch(request());

        return userService.create(user)
            .then(
                res => {
                    dispatch(success(res));
                    dispatch(AlertActions.success(res.message));
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: userConstants.CREATE_REQUEST } }
    function success(res) { return { type: userConstants.CREATE_SUCCESS, user: res.payload } }
    function failure(error) { return { type: userConstants.CREATE_FAILURE, error } }
}

function resendInvitation(userId) {
    return dispatch => {
        dispatch(request());

        return userService.resendInvitation(userId)
            .then(
                res => {
                    dispatch(success(res.payload));
                    dispatch(AlertActions.success(res.message));
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: userConstants.RESEND_INVITATION_REQUEST } }
    function success(res) { return { type: userConstants.RESEND_INVITATION_SUCCESS } }
    function failure(error) { return { type: userConstants.RESEND_INVITATION_FAILURE, error } }
}

function update(user) {
    return dispatch => {
        dispatch(request());

        return userService.update(user)
            .then(
                res => {
                    dispatch(success(res));
                    dispatch(AlertActions.success(res.message));
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: userConstants.SAVE_REQUEST } }
    function success(res) { return { type: userConstants.SAVE_SUCCESS, user: res.payload } }
    function failure(error) { return { type: userConstants.SAVE_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        userService.delete(id)
            .then(
                res => {
                    dispatch(success(res.payload));
                    dispatch(AlertActions.success(res.message));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(AlertActions.error(error));
                }
            );
    };

    function request(id) { return { type: userConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: userConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: userConstants.DELETE_FAILURE, id, error } }
}
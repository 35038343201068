
import React from 'react';
import { connect } from 'react-redux';

import {
  Typography,
  Grid,
  Paper,
  Link,
  Checkbox,
  FormControlLabel,
  TextField,
  CssBaseline,
  Avatar,
  Button,
  Divider,
  CircularProgress,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import { history } from '../../helpers';
import { UserActions } from '../../actions';

import styles from './Login.style';


const defaultErrors = {
  email: '',
  password: '',
}

class Login extends React.Component {
  constructor(props) {
    super(props);

    // reset login status
    this.props.logout();

    this.state = {
      email: '',
      password: '',
      errors: {
        ...defaultErrors,
      },
      submitted: false
    };
  }

  componentDidMount() {
    const { loginWithGoogle, loggedIn } = this.props;
    const { state } = history.location;

    if (state && state.code && !loggedIn)
      return loginWithGoogle(state.code);
  }

  componentDidUpdate(prevProps) {
    const { loggedIn } = this.props;
    const { loggedIn: prevLoggedIn } = prevProps;

    if (prevLoggedIn !== loggedIn)
      history.push('/home');
  }

  render() {
    const { loggingIn } = this.props;
    const { email, password, submitted, errors } = this.state;

    return (
      <Grid container component="main" style={styles.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} style={styles.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div style={styles.paper}>
            <div style={styles.titleContainer}>
              <Typography component="h1" variant="h4" style={styles.title}>
                  EF Finance Cockpit
              </Typography>
            </div>
            <div style={styles.formWrapper}>
              <div style={styles.formTitle}>
                <Avatar style={styles.avatar}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography style={{ marginLeft: 16 }} component="h1" variant="h5" >
                  Sign in to continue
                </Typography>
              </div>
              <div style={styles.googleBtnWrapper}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={<div style={styles.iconGoogle}/>}
                  style={!loggingIn ? styles.googleBtn : styles.googleBtnDisabled}
                  onClick={this.handleGoogleAuth}
                  disabled={loggingIn}
                >
                  Sign in with Google
                </Button>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    );
  }

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState({ [name]: value });
  }

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({ submitted: true }, () => {
      const { email, password } = this.state;
      const { login } = this.props;

      if (this.validate())
        login(email, password);

      this.setState({ submitted: false });
    });
  }

  handleGoogleAuth = (event) => {
    event.preventDefault();

    const url = this.getGoogleUrl();

    window.location.href = url;
  }

  buildQueryString = (queryObj) => {
    if (!queryObj || !Object.keys(queryObj).length)
      return '';

    const params = Object.keys(queryObj).map((q) => {
      const value = typeof queryObj[q] !== 'string' ? JSON.stringify(queryObj[q]) : queryObj[q];

      return `${encodeURIComponent(q)}=${encodeURIComponent(value)}`;
    });

    return `?${params.join('&')}`;
  }

  getGoogleUrl = () => {
    const {
      REACT_APP_EF_GOOGLE_CLIENT_ID: CLIENT_ID,
      REACT_APP_EF_GOOGLE_REDIRECT_URL: REDIRECT_URL,
      REACT_APP_EF_GOOGLE_SCOPES: GOOGLE_SCOPES,
    } = process.env;

    const stringifiedParams = this.buildQueryString({
      client_id: CLIENT_ID,
      redirect_uri: REDIRECT_URL,
      scope: GOOGLE_SCOPES,
      response_type: 'code',
      access_type: 'offline',
      prompt: 'consent',
    });

    return `https://accounts.google.com/o/oauth2/v2/auth${stringifiedParams}`;
  }
}

function mapState(state) {
  const { loggingIn, loggedIn } = state.authentication;

  return { loggingIn, loggedIn };
}

const actionCreators = {
  login: UserActions.login,
  loginWithGoogle: UserActions.loginWithGoogle,
  logout: UserActions.logout
};

const connectedLoginPage = connect(mapState, actionCreators)(Login);
export default connectedLoginPage;
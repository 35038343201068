import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { adminModal } from './adminModal.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import { report } from './report.reducer';

const rootReducer = combineReducers({
  authentication,
  users,
  adminModal,
  alert,
  report,
});

export default rootReducer;
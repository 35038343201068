import { COLORS, TYPOGRAPHY } from '../../foundation';

export default {
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url('${process.env.PUBLIC_URL}/images/ef-diamond-min.png')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '50%',
    backgroundPosition: 'center',
    backgroundColor: COLORS.GREY_LIGHT_DEFAULT,
  },
  paper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20,
  },
  title: {
    fontWeight: 'bold',
  },
  avatar: {
    backgroundColor: COLORS.PRISM.LIGHT_BLUE,
    marginTop: 10,
    marginBottom: 10,
  },
  formTitle: {
    width: '100%', // Fix IE 11 issue.
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  submit: {
    marginTop: 20,
    marginBottom: 20,
    width: '50%',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 50,
    paddingRight: 50,
  },
  divider: {
    width: '20%',
    marginTop: 16,
    marginBottom: 16,
  },
  iconGoogle: {
    color: COLORS.GOOGLE.RED,
    width: 30,
    height: 30,
    backgroundImage: `url('${process.env.PUBLIC_URL}/images/google-icon.png')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  googleBtnWrapper: {
    marginTop: 16,
  },
  googleBtn: {
    ...TYPOGRAPHY.PR,
    fontWeight: 600,
    paddingTop: 10,
    paddingBottom: 10,
    color: COLORS.TEXT.DEFAULT,
    backgroundColor: COLORS.WHITE.DEFAULT,
  },
  googleBtnDisabled: {
    color: 'rgba(0, 0, 0, 0.26)',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
  buttonProgress: {
    color: COLORS.WHITE.DEFAULT,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
};